import { CSSProperties, useEffect, useState } from 'react';

import {
  AudurMarketInterestRates,
  AudurMarketInterestRatesConnectionConnection,
  AudurMarketInterestRatesFutureMarketInterestRates,
  AudurMarketInterestRatesSavingsMarketInterestRates,
  AudurMarketInterestRatesTermDepositMarketInterestRates,
  Maybe,
  Scalars,
} from '@kvika/audur-prismic-types';
import { getNodesFromConnection } from '@kvika/audur-prismic-client';

import { ProductSchema, ProductType } from '@kvika/audur-api-types';
import SavingsCalculator from './SavingsCalculator';
import TermDepositCalculator from './TermDepositCalculator';
import CalculatorTabs from './CalculatorTabs';
import { TranslatedStrings } from '../../data/TranslatedStrings';
import { CalculatorTab } from '../../types/Types';
import styles from './Calculator.module.scss';
import FutureCalculator from './FutureCalculator';
import CompanyCalculator from './CompanyCalculator';

type Props = {
  allAudurProducts: ProductSchema[];
  allAudurMarketInterestRates: AudurMarketInterestRatesConnectionConnection;
  style?: CSSProperties;
};

const Calculator = ({ allAudurProducts, allAudurMarketInterestRates, style }: Props) => {
  const { calculatorStrings } = TranslatedStrings.is;
  const [selectedTab, setSelectedTab] = useState<CalculatorTab>(CalculatorTab.SavingsCalculator);
  const [savingsProduct, setSavingsProduct] = useState<ProductSchema>();
  const [companySavingsProduct, setCompanySavingsProduct] = useState<ProductSchema>();
  const [termDepositProducts, setTermDepositProducts] = useState<Array<ProductSchema>>([]);
  const [futureProduct, setFutureProduct] = useState<ProductSchema>();
  const [audurMarketInterestRates, setAudurMarketInterestRates] = useState<AudurMarketInterestRates>();
  const [savingsMarketInterestRates, setSavingsMarketInterestRates] =
    useState<AudurMarketInterestRatesSavingsMarketInterestRates>({
      lowDepositRate: 3.18,
      midDepositRate: 3.21,
      midToHighDepositRate: 3.24,
      highDepositRate: 3.27,
    });
  const [termDepositMarketInterestRates, setTermDepositMarketInterestRates] =
    useState<AudurMarketInterestRatesTermDepositMarketInterestRates>({
      threeMonthTermRate: 4.15,
      sixMonthTermRate: 4.33,
      twelveMonthTermRate: 4.75,
    });
  const [futureMarketInterestRates, setFutureMarketInterestRates] =
    useState<AudurMarketInterestRatesFutureMarketInterestRates>({
      averageMarketFutureRate: 0.37,
    });
  const [companyMarketInterestRates, setCompanyMarketInterestRates] =
    useState<AudurMarketInterestRatesSavingsMarketInterestRates>({
      lowDepositRate: 7.43,
      midDepositRate: 7.48,
      midToHighDepositRate: 7.54,
      highDepositRate: 7.6,
    });
  const [inflationRate, setInflationRate] = useState<Maybe<Scalars['Float']>>(2.5);

  useEffect(() => {
    const extractedNodes: Array<AudurMarketInterestRates> = getNodesFromConnection(allAudurMarketInterestRates);
    setAudurMarketInterestRates(extractedNodes[0]);
  }, [allAudurMarketInterestRates]);

  useEffect(() => {
    if (audurMarketInterestRates) {
      const savingsArray = audurMarketInterestRates.savingsMarketInterestRates;
      if (savingsArray && savingsArray.length > 0) {
        setSavingsMarketInterestRates(savingsArray[0]);
      }
      const termDepositArray = audurMarketInterestRates.termDepositMarketInterestRates;
      if (termDepositArray && termDepositArray.length > 0) {
        setTermDepositMarketInterestRates(termDepositArray[0]);
      }
      const futureArray = audurMarketInterestRates.futureMarketInterestRates;
      if (futureArray && futureArray.length > 0) {
        setFutureMarketInterestRates(futureArray[0]);
      }
      const companyArray = audurMarketInterestRates.companyMarketInterestRates;
      if (companyArray && companyArray.length > 0) {
        setCompanyMarketInterestRates(companyArray[0]);
      }
      const { inflationRateGoal } = audurMarketInterestRates;
      if (inflationRateGoal) {
        setInflationRate(inflationRateGoal);
      }
    }
  }, [audurMarketInterestRates]);

  useEffect(() => {
    const savingsProducts = allAudurProducts.filter((p) => p.type === ProductType.Savings);
    setSavingsProduct(savingsProducts[0]);
    const termDepositProducts = allAudurProducts.filter((p) => p.type === ProductType.TermDeposit);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const companySavingsProducts = allAudurProducts.filter((p) => p.type === 'company_savings');

    // only set companySavingsProduct if it exists
    const product = companySavingsProducts?.[0];
    if (product) setCompanySavingsProduct(product);

    setTermDepositProducts(termDepositProducts);
    const futureProduct = allAudurProducts.find((p) => p.type === ProductType.Future);
    setFutureProduct(futureProduct);
  }, [allAudurProducts]);

  const renderCalculator = () => {
    switch (selectedTab) {
      case CalculatorTab.TermCalculator: {
        return (
          <TermDepositCalculator
            initialBalance={10000}
            products={termDepositProducts}
            termDepositMarketInterestRates={termDepositMarketInterestRates}
          />
        );
      }

      case CalculatorTab.FutureCalculator: {
        return (
          futureProduct && (
            <FutureCalculator
              product={futureProduct}
              futureMarketInterestRates={futureMarketInterestRates}
              inflationRate={inflationRate ?? 0}
            />
          )
        );
      }

      case CalculatorTab.CompanyCalculator: {
        return (
          <CompanyCalculator
            interestRate={companySavingsProduct?.interestRate ?? 0}
            companyMarketInterestRates={companyMarketInterestRates}
          />
        );
      }

      default: {
        return (
          savingsProduct && (
            <SavingsCalculator
              initialBalance={10000}
              product={savingsProduct}
              savingsMarketInterestRates={savingsMarketInterestRates}
            />
          )
        );
      }
    }
  };

  return (
    <div style={style}>
      <h3 className={`dark ${styles.heading}`}>{calculatorStrings.title}</h3>
      <CalculatorTabs selectedTab={selectedTab} onSwitchTabs={setSelectedTab} />
      <div>{audurMarketInterestRates ? renderCalculator() : null}</div>
    </div>
  );
};

export default Calculator;
